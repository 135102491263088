@font-face {
  font-family: 'fontello';
  src: url(/static/media/fontello.1f0ad432.eot);
  src: url(/static/media/fontello.1f0ad432.eot#iefix) format('embedded-opentype'),
       url(/static/media/fontello.d0c3fa70.woff2) format('woff2'),
       url(/static/media/fontello.e8476e4f.woff) format('woff'),
       url(/static/media/fontello.ddc7370b.ttf) format('truetype'),
       url(/static/media/fontello.cec66889.svg#fontello) format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-star:before { content: '\2605'; }
.icon-star-empty:before { content: '\2606'; }
.icon-star-half:before { content: '\e701'; }
.icon-star-half-alt:before { content: '\f123'; }

@font-face {
    font-family: 'Didot';
    src: url(/static/media/didot.3e37a5da.woff2) format('woff2'),
        url(/static/media/didot.da3d8aca.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


